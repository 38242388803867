import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atoms/Link';

import breakpoints from 'styles/breakpoints';

import RevealElement from 'components/atoms/RevealElement';
import { BodyText, H0_5 } from 'components/atoms/Typography';
import { Section } from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';

import Slider from 'components/molecules/Slider';
import { storyblokEditable } from '@storyblok/react';

const Organism = styled(Section)`
    .slick-slide {
        &:not(.slick-current) {
            .productHeader,
            .productAction {
                opacity: 0;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 20px;
        z-index: 10;
    }
    .slick-prev {
        right: 39px;
        left: auto;
    }
    .slick-next {
        right: 0px;
        left: auto;
    }
    @media screen and ${breakpoints.maxMd} {
        .slick-arrow {
            display: none;
        }
    }
    .slick-list {
        @media screen and ${breakpoints.maxMd} {
            overflow: visible;
        }
    }
`;

const FeaturedProducts = ({ blok }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
    const [maxSlideIndex, setMaxSlideIndex] = useState(1);

    const handleCustomPaging = () => {
        const dots = [...document.querySelectorAll(`#section-${blok._uid} .customDots > li`)];
        const dotCount = dots.length;
        const dotIndex =
            dots.findIndex(dot => dot.className.includes('slick-active')) === -1
                ? dotCount
                : dots.findIndex(dot => dot.className.includes('slick-active')) + 1;
        const slideIndex = dotIndex;
        setCurrentSlideIndex(slideIndex);
        setMaxSlideIndex(dotCount);
    };

    useEffect(() => {
        setTimeout(() => {
            if (blok?.products?.length) handleCustomPaging();
        }, [200]);
    }, [blok]);

    const settings = {
        adaptiveHeight: true,
        dots: true,
        dotsClass: 'customDots hidden',
        appendDots: dots => {
            return <ul>{dots}</ul>;
        },
        onReInit: handleCustomPaging,
        draggable: false,
        infinite: false,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    draggable: true,
                    fade: false,
                    speed: 700,
                    slidesToShow: 1.05,
                },
            },
        ],
    };

    return (
        <Organism
            id={`section-${blok?._uid}`}
            className={`container overflow-hidden my-16 lg:my-24 relative ${
                maxSlideIndex < 2 ? 'slideNav-hidden' : ''
            }`}
            padding="28px 60px"
            paddingMobile="0 32px 32px"
            {...storyblokEditable(blok)}
        >
            <RevealElement className="relative -mx-2 lg:mx-0">
                <Slider settings={settings}>
                    {blok?.products?.map(product => (
                        <div
                            key={product._uid}
                            className="outline-none px-2 lg:px-0 lg:pb-1.5"
                            {...storyblokEditable(product)}
                        >
                            <div className="flex flex-col lg:grid gap-x-40px grid-cols-12 lg:mb-0 relative pb-12 lg:pb-0">
                                <div className="col-span-3 lg:col-span-1 flex flex-col justify-end absolute lg:relative bottom-0 right-0">
                                    <BodyText color="#7f7f7f" align="right">
                                        {product.location}
                                    </BodyText>
                                </div>
                                <div className="order-2 lg:order-1 col-span-5 imageBox">
                                    {product?.image?.filename && (
                                        <Image
                                            className="w-full pointer-events-none"
                                            src={product.image.filename}
                                            alt={
                                                product.image.alt ||
                                                `${product.title} - featured image`
                                            }
                                            width={543}
                                            height={724}
                                        />
                                    )}
                                </div>
                                <div className="order-1 lg:order-2 col-span-3 flex flex-col justify-between productHeader">
                                    <div className="max-w-sm">
                                        <H0_5
                                            as="h2"
                                            className="mb-6 lg:mb-3"
                                            fontSizeMobile="2.5rem"
                                        >
                                            {product.title}
                                        </H0_5>
                                        <BodyText className="mb-6" fontSize="1.25rem">
                                            {product.description}
                                        </BodyText>
                                    </div>
                                </div>
                                {product?.buttonText && product?.buttonText !== '' && (
                                    <div className="order-3 lg:order-3 col-span-3 flex flex-col justify-end lg:items-end productAction">
                                        <Link href={product?.buttonLink || '#'}>
                                            <Button
                                                as="span"
                                                type="primary"
                                                label={product.buttonText}
                                                className="w-full lg:w-52 mt-7 lg:mt-0 z-10"
                                            />
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </Slider>
            </RevealElement>
            {maxSlideIndex > 1 && (
                <div className="lg:ml-m-sm absolute left-0 ml-m-md bottom-8 lg:bottom-7 lg:left-1/2 flex items-end">
                    <H0_5
                        as="span"
                        lineHeight="1"
                        fontWeightMobile="normal"
                        fontSizeMobile="16px"
                        lineHeightMobile="1.5"
                        fontFamilyMobile="text"
                    >
                        <RevealElement as="span" delay={0.1} start="top 100%">
                            0{currentSlideIndex}
                        </RevealElement>
                    </H0_5>
                    <BodyText
                        as="span"
                        color="rgb(var(--subdued-text-color))"
                        margin="0 0 0 .75rem"
                    >
                        <RevealElement
                            as="span"
                            delay={0.3}
                            start="top 100%"
                        >{`/ 0${blok.products.length}`}</RevealElement>
                    </BodyText>
                </div>
            )}
        </Organism>
    );
};

FeaturedProducts.defaultProps = {
    blok: {},
};

FeaturedProducts.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

export default FeaturedProducts;
